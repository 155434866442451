<script lang="ts" setup>

    import event from "@/helpers/eventbus";
    import {ref} from "vue";

    const show = ref(false);
    const left = ref(0);
    const top = ref(0);
    const text = ref('');
    const raw = ref(false);

    event.$on('show-tooltip',(event,args)=>{

        const targetPos = event.srcElement.getBoundingClientRect();

        left.value = event.srcElement.offsetLeft + (targetPos.width / 2);
        top.value = event.srcElement.offsetTop+targetPos.height+15;
        text.value = args.value;
        raw.value = !!args?.modifiers?.raw;

        show.value = true;
    });

    event.$on('hide-tooltip',()=>{
        show.value = false;
    });

</script>


<template>
    <div class="tooltip" v-if="show" :style="{left: left+'px', top: top+'px'}">
        <template v-if="raw">
            {{ text }}
        </template>
        <template v-else>
            {{$t(text)}}
        </template>
    </div>
</template>

<style lang="scss" scoped>


    .tooltip{
        background: var(--smt-text-color);
        box-shadow: var(--smt-box-shadow);
        color: white;
        font-size: 11px;
        padding: 6px;
        border-radius: 4px;
        position: absolute;
        z-index: 999999999999;
        transform: translate(-50%,0px);
    }

</style>