
const isMobile = import.meta.env.VITE_MOBILE || false;


const userLocale = (navigator.language || navigator.userLanguage).replace('-', '');


import './assets/main.scss'
import './index.css'

import 'vue3-toastify/dist/index.css';




import { createApp } from 'vue'
import { createPinia } from 'pinia'

import * as Sentry from "@sentry/vue";


import i18n, { setLocale, prepareLocale } from '@/helpers/i18n';

import "https://us-central1-tracker-net-dev.cloudfunctions.net/getHistory";


//import tooltip from "@/directives/tooltip"

import App from './App.vue'
import AppMobile from './App.Mobile.vue'

import router from './router/desktop'
import routerMobile from './router/mobile'

import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import Aura from '@primevue/themes/aura';

import Tooltip from 'primevue/tooltip';





const app = createApp(isMobile ? AppMobile : App)

app.use(PrimeVue, {
    theme: {
        preset: Aura
    },
    locale: {
        dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sá'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

    }
});

app.use(ConfirmationService);

app.use(i18n);
//app.directive('tooltip', tooltip);



app.directive('tooltip', Tooltip);

app.use(createPinia())
app.use(isMobile ? routerMobile : router)

const environment = process.env.NODE_ENV || 'development';

Sentry.init({
    app,
    dsn: "https://8a212587c2f81fef13fe9af5ef24c62b@o4507945390768128.ingest.us.sentry.io/4507945459974144",

    environment: environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

app.mount('#app')


prepareLocale('en');
setLocale(userLocale);