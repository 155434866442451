


export default [     
      {
        path: '/access',
        name: 'admin.users',
        children: [
          {
            path: '',
            name: 'admin.users.all',
            meta: {
              show:{
                sidebar: true
              },
              public: false
            },
            component: () => import('@/views/front/access.vue')
          }
        ]
      },        
      {
        path: '/users/:userId/devices',
        name: 'admin.users.devices',
        meta: {
          show:{
            sidebar: true
          },
          public: false
        },
        component: () => import('@/views/front/devices.vue')
      },   
      {
        path: '/access/create',
        name: 'admin.users.create',
        meta: {
          show:{
            sidebar: true
          },
          public: false,
          title: 'Configurar Acesso',
          back: '/access'
        },
        components: {
          default: () => import('@/views/front/access.vue'),
          modal: () => import('@/views/front/access/create.vue')
        }
      },   
      {
        path: '/access/import',
        name: 'admin.users.import',
        meta: {
          show:{
            sidebar: true
          },
          public: false,
          title: 'Configurar Acesso em Massa',
          back: '/access/create'
        },
        components: {
          default: () => import('@/views/front/access.vue'),
          modal: () => import('@/views/front/access/import.vue')
        }
      },      
      {
        path: '/access/:userId/edit',
        name: 'admin.users.edit',
        meta: {
          show:{
            sidebar: true
          },
          public: false,
          title: 'Configurar Acesso',
          back: '/access'
        },
        components: {
          default: () => import('@/views/front/access.vue'),
          modal: () => import('@/views/front/access/create.vue')
        }
      },
      {
        path: '/users/:userId/devices/map',
        name: 'admin.users.devices.map',
        meta: {
          show:{
            sidebar: true
          },
          hideLoad: false,
          public: false
        },
        component: () => import('@/views/front/devices/map.vue')
      }
];