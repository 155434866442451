<script lang="ts" setup>

//@ts-ignore
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//@ts-ignore
import { faBars,faMap,faFileChartLine,faChartColumn,faMoneyCheckDollarPen, faUserAlt, faBell, faCar, faWrench, faVideo, faGear, faMessagesQuestion } from '@fortawesome/pro-light-svg-icons' 

import {computed} from "vue";
import { useSessionStore } from '@/stores/session';


const session = useSessionStore();

const getImage = computed(()=>{
  const user = session.user;

  if(user.image && user.image != ""){
    return user.image;
  }else{
      return 'https://storage.googleapis.com/tracker-net-permanent/legacy/sp/images/logos/logo-21930-PwMzvPwr2klWupRv..png';
  }
})

</script>


<template>
    <div class="menu overflow-auto w-[70px] bg-white shadow h-screen">
      <ul>
        <Router-Link to="/objects" custom v-slot="{ isExactActive, href, navigate }">
          <li>
            <a href="/objects"  :class="{isExactActive}" > <FontAwesomeIcon :icon="faBars"></FontAwesomeIcon></a>
          </li>
        </Router-Link>

        <Router-Link to="/objects" custom v-slot="{ isExactActive, href, navigate }">
          <li >
            <a href="/objects"  :class="{isExactActive}"  style="padding: 0px;background: none !important;">
              <img width="50px" :src="getImage" />
            </a>
          </li>
        </Router-Link>

        <Router-Link to="/objects/devices" custom v-slot="{ isExactActive, href, navigate }">
          <li>
            <a href="/objects" :class="{isExactActive}" > <FontAwesomeIcon :icon="faMap"></FontAwesomeIcon></a>
          </li>
        </Router-Link>
        <Router-Link to="/admin/home/index" custom v-slot="{ isExactActive, href, navigate }">
          <li>
            <a href="/admin/home/index"  :class="{isExactActive}" > <FontAwesomeIcon :icon="faChartColumn"></FontAwesomeIcon></a>
          </li>
        </Router-Link>
        

        
        <Router-Link to="/admin/home/finance" custom v-slot="{ isExactActive, href, navigate }">
          <li>
            <a href="/admin/home/fiance"  :class="{isExactActive}" > <FontAwesomeIcon :icon="faMoneyCheckDollarPen"></FontAwesomeIcon></a>
          </li>
        </Router-Link>

        
        <Router-Link to="/admin/users" custom v-slot="{ isActive, href, navigate }">
          <li>
            <a href="/admin/users/clients"  :class="{isActive}"> <FontAwesomeIcon :icon="faUserAlt"></FontAwesomeIcon></a>
          </li>
        </Router-Link>

        
        <Router-Link to="/admin/users/objects" custom v-slot="{ isExactActive, href, navigate }">
          <li>
            <a href="/admin/users/objects"  :class="{isExactActive}"> <FontAwesomeIcon :icon="faCar"></FontAwesomeIcon></a>
          </li>
        </Router-Link>

           
        <Router-Link to="/admin/events" custom v-slot="{ isExactActive, href, navigate }">
          <li>
            <a href="/events_management"  :class="{isExactActive}"> <FontAwesomeIcon :icon="faBell"></FontAwesomeIcon></a>
          </li>
        </Router-Link>
        
        <Router-Link to="/objects/reports" custom v-slot="{ isExactActive, href, navigate }">
          <li>
            <a href="/reports/create_modal"  :class="{isExactActive}"  > <FontAwesomeIcon :icon="faFileChartLine"></FontAwesomeIcon></a>
          </li>
        </Router-Link>

        
        <Router-Link to="/admin/maintenance" custom v-slot="{ isExactActive, href, navigate }">
          <li>
            <a href="/maintenance"  :class="{isExactActive}" > <FontAwesomeIcon :icon="faWrench"></FontAwesomeIcon></a>
          </li>
        </Router-Link>

        
        <Router-Link to="/objects/reports" custom v-slot="{ isExactActive, href, navigate }">
          <li>
            <a href="/smartcam"  :class="{isExactActive}"  > <FontAwesomeIcon :icon="faVideo"></FontAwesomeIcon></a>
          </li>
        </Router-Link>

        
        <Router-Link to="/objects/reports" custom v-slot="{ isExactActive, href, navigate }">
          <li>
            <a href="/admin/gerenciar/index"  :class="{isExactActive}"  > <FontAwesomeIcon :icon="faGear"></FontAwesomeIcon></a>
          </li>
        </Router-Link>

        
        <!-- <Router-Link to="/objects/reports" custom v-slot="{ isExactActive, href, navigate }">
          <li>
            <a :href="href"  :class="{isExactActive}"  > <FontAwesomeIcon :icon="faMessagesQuestion"></FontAwesomeIcon></a>
          </li>
        </Router-Link> -->
      </ul>
    </div>
</template>


<style scoped lang="scss">
  .menu{
    &::-webkit-scrollbar {
      width: 1px;
      height: 1px;
    }
  }


  .menu ul li a{

    @apply text-neutral-900 text-[16px] text-center block p-[10px] m-[10px] rounded-md bg-transparent transition-all;  

  
  }

  
  .menu ul li a:hover,.isExactActive, .isActive{
    @apply bg-sky-100 !important;
  }
</style>