<script lang="ts" setup>

    import event from "@/helpers/eventbus";
    import {ref} from "vue";

    const started = ref(0);
    const loading = ref(false);
    const acelerate = ref(false);

    event.$on('page-loading',()=>{
        loading.value = true;
        acelerate.value = false;
        started.value = new Date().getTime();
    });

    event.$on('page-loaded',()=>{

        const diff = (new Date().getTime() - started.value) + 1000;

        acelerate.value = diff;

        window.setTimeout(()=>{            
            loading.value = false;
        },1300);
    });

</script>


<template>
    <div style="position: fixed;left: 0px;top: 0px;width: 100vw;z-index: 9999999999;">
        <div class="page-loader" v-if="loading" >
            <div class="bar" >
                <div class="fill" :style="(acelerate?'animation-duration: '+acelerate+'ms;':'')"></div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

    @keyframes pageloadanimation {
        0% {
            width: 0%;
        }
        10% {
            width: 50%;
        }
        50% {
            width: 70%;
        }
        100% {
            width: 100%;
        }
    }
    .page-loader{
        position: relative;


        .bar{
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100vw;
            height: 4px;
            background: var(--smt-bg-color);

            .fill{
                background: var(--smt-primary-color);
                height: 4px;
                animation: pageloadanimation 90s ease-in;
            }
        }
    }

</style>