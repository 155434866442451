


export default [       
      {
        path: '/admin/users',
        name: 'admin.users',
        meta: {
          show:{
            sidebar: true
          },
          public: false
        },
        children:[
          {
            path: ':userId',
            name: 'admin.users.create',
            meta: {
              show:{
                sidebar: true
              },
              public: false
            },
            component: () => import('@/views/admin/users/create.vue'),
            children:[
              {
                path: 'main',
                name: 'admin.users.create.main',
                meta: {
                  show:{
                    sidebar: true
                  },
                  public: false
                },
                component: () => import('@/views/admin/users/create/index.vue')
              },
              {
                path: 'permissions',
                name: 'admin.users.create.permissions',
                meta: {
                  show:{
                    sidebar: true
                  },
                  public: false
                },
                component: () => import('@/views/admin/users/create/permissions.vue')
              },
              {
                path: 'devices',
                name: 'admin.users.create.devices',
                meta: {
                  show:{
                    sidebar: true
                  },
                  public: false
                },
                component: () => import('@/views/admin/users/create/devices.vue')
              },
              {
                path: 'client',
                name: 'admin.users.create.client',
                meta: {
                  show:{
                    sidebar: true
                  },
                  public: false
                },
                component: () => import('@/views/admin/users/create/client.vue')
              }
            ]
          }
        ]
      }
];