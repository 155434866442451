export default [    
    {
        path: '/login',
        name: 'login',
        meta: {
          show:{
            sidebar: false
          },
          public: true
        },
        component: () => import('@/views/public/login.vue')
      }
]