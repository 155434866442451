// src/stores/user.js
import { defineStore } from 'pinia';
import {ref,computed} from 'vue';

import type { User } from '@/types/User';

import {jwtDecode} from 'jwt-decode';

export const useSessionStore = defineStore('session',()=>{
  
    const token = ref<string | null>(null);

    const loadToken = ()=>{
        const tmp = window.localStorage.getItem('smt-token');
        if(tmp){
            token.value = tmp;
        }
    }

    const setToken = (_token: string)=>{
        window.localStorage.setItem('smt-token',_token);
        token.value = _token;
    }

    const clearSession = ()=>{
      token.value = null;
      window.localStorage.clear();
    }

    const checkSession = ()=>{
       
        return new Promise((resolve)=>{
            if(!!token.value){
                
                try {
                    const data  = jwtDecode(token.value) as {exp: number;};

                    console.log("Token will expire in "+((data.exp - (Date.now()/1000)))+" seconds");

                    if(data.exp < (Date.now() / 1000)){

                        clearSession();

                        resolve(false);
                    }else{
                        resolve(true);
                    }

                } catch (error) {
                    console.error('Invalid JWT:', error);
                    resolve(false);
                }
            }else{
               resolve(false);
            }
        })
        
    }

    const isAuthenticated = computed(()=>{
        return !!token.value;
    });


    const user = computed(()=>{
        return jwtDecode(token.value ?? "" as string) as User;
    })
 

    loadToken();
    return {isAuthenticated,checkSession,setToken,user,token}
});
