


export default [    
    {
        path: '/devices',
        name: 'admin.devices',
        children: [
                {
                  path: '',
                  meta: {
                    show:{
                      sidebar: true
                    },
                    public: false
                  },
                  component: () => import('@/views/front/devices.vue')
                },
                {
                  path: 'create',
                  children:[
                    {
                      path: '',
                      name: 'admin.devices.add',
                      meta:{
                        title: 'devices.add.title',     
                        show:{
                          sidebar: true
                        },
                        public: false
                      },
                      components: {
                        default: () => import('@/views/front/devices.vue'),
                        modal: () => import('@/views/front/devices/create-new/select-method.vue')
                      }
                    },
                    {
                      path: 'individual',
                      children: [
                          {
                            path: '',
                            name: 'admin.devices.add.invididual',
                            meta:{
                              title: 'devices.add.destination',     
                              show:{
                                sidebar: true
                              },
                              public: false
                            },
                            components: {
                              default: () => import('@/views/front/devices.vue'),
                              modal: () => import('@/views/front/devices/create-new/select-destination.vue')
                            }
                          },
                          {
                            path: 'user',
                            name: 'admin.devices.add.invididual.user',
                            meta:{
                              title: 'devices.add.user',     
                              show:{
                                sidebar: true
                              },
                              public: false
                            },
                            components: {
                              default: () => import('@/views/front/devices.vue'),
                              modal: () => import('@/views/front/devices/create-new/select-user.vue')
                            }
                          }

                      ]
                    }
                  ]
                },
                {
                  path: 'groups',
                  name: 'admin.devices.groups',
                  meta: {
                      show: {
                        sidebar: true
                      },
                      public: false
                  },
                  component: () => import('@/views/front/devices/groups.vue')
                },
                {
                  path: 'map',
                  name: 'admin.devices.map',
                  meta: {
                    show:{
                      sidebar: true
                    },
                    hideLoad: false,
                    public: false
                  },
                  component: () => import('@/views/front/devices/map.vue')
                }
        ]
      },         

];