import { createRouter, createWebHistory } from 'vue-router'

import { useSessionStore } from '@/stores/session';
import eventBus from "@/helpers/eventbus"

import publicRoutes from "./public"
import dashboardRoutes from "./dashboard"
import devicesRoutes from "./devices"
import userRoutes from "./users"
import adminRoutes from "./admin"



const router = createRouter({
  history: createWebHistory("/alpha/"),
  routes: [
    ...publicRoutes,
    ...devicesRoutes,
    ...userRoutes,
    ...adminRoutes,
    {
      path: '/:pathMatch(.*)*',  // Rota de fallback para redirecionar qualquer rota desconhecida
      redirect: '/devices/map'
    }
  ]
})


router.beforeEach(async (to, from) => {

    eventBus.$emit('page-loading');
    let permit = false;

    const userSession = useSessionStore();

    if(to.query.t){
      userSession.setToken(to.query.t);


      const query = { ...to.query };
      delete query.t;


  
      return { ...to, query };

    }
    
    if(to.hasOwnProperty('meta') && to.meta.hasOwnProperty('public') ){

        if(to.meta.public == true){
          permit = true;
        }

        await userSession.checkSession().catch(() => { });

        if(to.meta.public == false && !userSession.isAuthenticated){
          return '/login'
        }else{
          permit = true;
        }
    }

    if(permit){
      
      const routed = to.matched.find((record :any) => {
          return record.components && record.components.modal
      });

      console.log(routed,from.name);

      if (!from.name || !routed) {
          return true;
      }else{   
          router.options.history.push(to.fullPath);
          eventBus.$emit('open-modal',to);
          eventBus.$emit('page-loaded');

          return false;
      }
    }
});


router.afterEach((to, from) => {
  if(to.hasOwnProperty('meta') && to.meta.hasOwnProperty('hideLoad') && to.meta.hideLoad == true || !to.hasOwnProperty('meta') || !to.meta.hasOwnProperty('hideLoad')){
    eventBus.$emit('page-loaded');
  }
});

export default router
