import { createRouter, createWebHistory } from 'vue-router'

import { useSessionStore } from '@/stores/session';
import eventBus from "@/helpers/eventbus"



const router = createRouter({
  history: createWebHistory("/"),
  routes: [
    {
      path: '/home',
      component: ()=>import("@/views/mobile/blank.vue")
    },
    {
      path: '/devices/:uniqueId/playback',
      children: [
        {
          path: '',
          name: 'playback',
          component: ()=>import("@/views/mobile/devices/playback.vue")
        }
      ]

    },
    {
      path: '/devices/:uniqueId/expenses',
      children: [
        {
          path: '',
          name: 'expenses',
          component: ()=>import("@/views/mobile/devices/expenses.vue")
        },
        {
          path: 'create',
          name: 'expenses.create',
          component: ()=>import("@/views/mobile/devices/expenses/create.vue")
        },
        {
          path: ':expenseId/edit',
          name: 'expenses.edit',
          component: ()=>import("@/views/mobile/devices/expenses/create.vue")
        },
      ]
    },
    {
      path: '/devices/:uniqueId/history',
      children: [
        {
          path: '',
          name: 'history',
          component: ()=>import("@/views/mobile/devices/history.vue")
        },
        {
          path: 'positions',
          name: 'history.positions',
          component: ()=>import("@/views/mobile/devices/history/positions.vue")
        },
        {
          path: 'street',
          name: 'history.street',
          component: ()=>import("@/views/mobile/devices/history/street.vue")
        },
        {
          path: 'export',
          name: 'history.export',
          component: ()=>import("@/views/mobile/devices/history/export.vue")
        },
        {
          path: 'daily',
          name: 'history.map',
          component: ()=>import("@/views/mobile/devices/history/daily.vue")
        }
      ]
    },
    {
      path: '/:pathMatch(.*)*',  // Rota de fallback para redirecionar qualquer rota desconhecida
      redirect: '/home'
    }
  ]
})


router.beforeEach(async (to, from) => {

    eventBus.$emit('page-loading');
    let permit = false;

    
    const userSession = useSessionStore();

    if(to.query.t){
      userSession.setToken(to.query.t);


      const query = { ...to.query };
      delete query.t;


      console.log(query);
  
      return { ...to, query };

    }
    
    if(to.hasOwnProperty('meta') && to.meta.hasOwnProperty('public') ){

        if(to.meta.public == true){
          permit = true;
        }

        await userSession.checkSession().catch(() => { });

        if(to.meta.public == false && !userSession.isAuthenticated){
          return '/login'
        }else{
          permit = true;
        }
    }

    if(permit){
      
      const routed = to.matched.find((record :any) => {
          return record.components && record.components.modal
      });

      console.log(routed);

      if (!from.name || !routed) {
          return true;
      }else{   
          router.options.history.push(to.fullPath);
          eventBus.$emit('open-modal',to);
          eventBus.$emit('page-loaded');

          return false;
      }
    }
});


router.afterEach((to, from) => {
  if(to.hasOwnProperty('meta') && to.meta.hasOwnProperty('hideLoad') && to.meta.hideLoad == true || !to.hasOwnProperty('meta') || !to.meta.hasOwnProperty('hideLoad')){
    eventBus.$emit('page-loaded');
  }
});

export default router
