<script lang="ts" setup>

    import SmtRoundedBtn from "./smt-rounded-btn.vue"
   
    //@ts-ignore
    import { faXmark } from '@fortawesome/pro-thin-svg-icons' 
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

    import { useRoute,useRouter } from 'vue-router';
    import { ref, watch, shallowRef,markRaw,defineAsyncComponent } from "vue";
    import eventBus from "@/helpers/eventbus"

    const smtModalSrc = shallowRef<null | false | ReturnType<typeof defineAsyncComponent>>(null);

    eventBus.$on('open-modal',(params)=>{
            const routed = params.matched.find((record :any) => {
                return record.components && record.components.modal
            });


            if(routed){

                
                data.value = params;

                smtModalSrc.value = defineAsyncComponent(() => {
                    isLoading.value = true;

                    return new Promise((resolve, reject) => {
                        // Verifica se routed.components.modal ainda é uma função
                        if (typeof routed.components.modal === 'function') {
                            routed.components.modal()
                                .then((component) => {
                                    isLoading.value = false;
                                    resolve(component);
                                })
                                .catch((error) => {
                                    isLoading.value = false;
                                    reject(error);
                                });
                        } else {
                            // Se não for uma função, assume que é o componente diretamente
                            isLoading.value = false;
                            resolve(routed.components.modal);
                        }
                    });
                });

            
                modalTitle.value = routed?.meta?.title;
                modalBox.value = routed?.meta?.modalBox == null || routed?.meta?.modalBox == true;
                modalBack.value = routed?.meta?.back;

                console.log(modalBack.value);

                isModalOpen.value = true;
                document.body.classList.add("modal-open");               
            }else{
                smtModalSrc.value = false;
                isModalOpen.value = false;
                
                document.body.classList.remove("modal-open");
            }

    });

    const isLoaded = ()=>{
        console.log("OK");
    }

    const route = useRoute();
    const router = useRouter();

    const data = ref(null);

    const modalTitle = ref("");
    const modalBox = ref(true);
    const isModalOpen = ref(false);
    const isLoading = ref(false);
    const modalBack = ref(null);

    const checkSecondaryContent = () => {


        const routed = route.matched.find((record) => {
            return record.components && record.components.modal
        });


        data.value = route;
        
        isModalOpen.value = !!routed;  
        modalTitle.value = routed?.meta?.title;
        modalBox.value = routed?.meta?.modalBox == null || routed?.meta?.modalBox == true;
        modalBack.value = routed?.meta?.back;


        if(isModalOpen.value){
            document.body.classList.add("modal-open");    
        }else{
            document.body.classList.remove("modal-open");    
        }

    };

    const closeModal = ()=>{



        if(modalBack.value){
            
            router.push({
                path: modalBack.value,
                query: {
                    t: Date.now()
                }
            });
            
            
            isModalOpen.value = false;
            
        }else{
            
            isModalOpen.value = false;
        }


    }

    watch(route, () => {
      checkSecondaryContent();
    });

    // Initial check
    checkSecondaryContent();


</script>


<template>
    <div class="absolute w-full h-full hidden" :class="{'!block': isModalOpen}">
        <div class="bg-slate-200 opacity-50 absolute w-full h-full z-[999998]" @click="closeModal()"></div>
        <div class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[999999] bg-white min-w-96 rounded-md border shadow-sm p-4" :class="{'float-box': !modalBox}">
            <div v-if="modalBox" class="flex items-center justify-between border-b pb-2 mb-2">
                <div class="text-[16px] font-bold">{{ $t(modalTitle || 'general.noTitle') }}</div>
                <div @click="closeModal()" class="text-[20px] cursor-pointer">
                    <FontAwesomeIcon :icon="faXmark"></FontAwesomeIcon>
                </div>
            </div>
            <div class="modal-content">
                <div v-if="isLoading" class="loading">
                    
                </div>
                
                <Component v-if="smtModalSrc" :is="smtModalSrc" :data="data"></Component>
                <slot v-else></slot>
            </div> 
        </div>
    </div>
</template>


<style scoped lang="scss">


@keyframes l1 {to{transform: rotate(.5turn)}}


    .modal{
        position: absolute;
        display: none;
        width: 100vw;
        height: 100vh;
        z-index: 999990;
        color: #334257;

        .overlay{
            background: #334257CC;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100vw;
            height: 100vh;
            z-index: 999991;
            opacity: 1.5 !important;
        }
    
        .modal-box{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            z-index: 999992;

            /* UI Properties */
            background: var(--branco-background-) 0% 0% no-repeat padding-box;
            border: 1px solid var(--detalhes-e-bordas);
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 1px 4px #00000014;
            border: 1px solid #D9D9D9;
            border-radius: 10px;

            
            box-shadow: 0 0px 15px rgba(0, 0, 0, .35);

            .modal-header{
                
                position: relative;
            

                .modal-title{
                    font-size: 20px;
                    font-weight: 600;
                    padding: 10px;
                    text-align: center;
                    margin-right: 40px;
                }

                .modal-close{
                    position: absolute;
                    top: 5px;
                    right: 5px;
                }
            
            } 

            .modal-content{
                min-width: 400px;

                .loading{
                    min-height: 300px;
                    position: relative;

                    &:after {
                        width: 50px;
                        aspect-ratio: 1;
                        border-radius: 50%;
                        border: 8px solid;
                        border-color: var(--smt-primary-color) #0000;
                        animation: l1 0.5s infinite;
                        z-index: 999999;
                        
                        position: absolute;
                        left: calc(50% - 25px);
                        top: calc(50% - 25px);
                        content: "";
                    }
                }
            }

            &.float-box{
                background: none;
                border: none;
                box-shadow: none;
            }
        }

        

        &.show{
            display: block;
        }

       
        
    }

</style>