<script setup lang="ts">
    import SmtModal from "@/components/layout/smt-modal.vue"
    import SmtMenu from "@/components/layout/smt-menu.vue"
    import SmtPageLoader from "@/components/layout/smt-page-loader.vue"
    import SmtTooltip from "@/components/layout/smt-tooltip.vue"

    import {ref,watch, onMounted,computed} from "vue";
    import {useRoute} from "vue-router";


    
    const route = useRoute();

    const sidebarShow = ref(false);

    import { useSessionStore } from '@/stores/session';


    const session = useSessionStore();

    const getImage = computed(()=>{


      let img = 'https://storage.googleapis.com/tracker-net-permanent/legacy/sp/images/logos/logo-21930-PwMzvPwr2klWupRv..png';

      if(session.isAuthenticated){
        const user = session.user;

        if(user.image && user.image != ""){
          img = user.image;
        }
      }

      return img;
    })

    
    const getTitle = computed(()=>{


      let img = 'Plataforma de rastreamento IoT e gestão financeira';

      if(session.isAuthenticated){
        const user = session.user;

        if(user.serverName && user.serverName != ""){
          img = user.serverName;
        }
      }

      return img;
    })


    onMounted(()=>{

      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = getImage.value;
      document.getElementsByTagName('head')[0].appendChild(link);


      
      let title = document.querySelector("title") || document.createElement('title');
      title.innerText = getTitle.value;
      document.getElementsByTagName('head')[0].appendChild(title);


    })



    const checkRouteMeta = () => {
      if(route.hasOwnProperty('meta') && route.meta.hasOwnProperty('show') && route.meta.show.hasOwnProperty('sidebar')){
        sidebarShow.value = route.meta.show.sidebar;
      }
    };

    watch(route, () => {
      checkRouteMeta();
    });

    checkRouteMeta();

</script>

<template>
  <smt-page-loader></smt-page-loader>
  <smt-tooltip></smt-tooltip>
  <smt-modal>
    <RouterView name="modal"></RouterView>
  </smt-modal>
  <div style="display: flex;">
    <smt-menu v-if="sidebarShow"></smt-menu>
    
    <div style="flex: 1;overflow: auto;height: 100vh">
      <RouterView></RouterView>
    </div>
  </div>
  <div id="modals"></div>
</template>

<style>
/* Definir as classes de transição */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
